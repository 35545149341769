





















































import {
  Prop,
  Watch,
  Component,
  Vue
} from 'vue-property-decorator';
import LeaseItemEditTextItem, {
  ILeaseItemEditTextItem
} from '@/modules/LeaseItem/EditTextItem';
import {
  ITextItemList_Item
} from '@/modules/TextItem/List';
import User from '@/modules/User';
import LeaseEdit from '@/modules/Lease/Edit';
import VatList from '@/modules/Vat/List';

@Component
export default class EditTextItem extends Vue {
  @Prop() readonly isEdit ? : boolean;

  isBusy = true;
  stockItemSelectInvalidFeedback ? = null as string | null;
  leaseInfo: LeaseEdit | null = new LeaseEdit();
  leaseItem = new LeaseItemEditTextItem();

  onTextItemSelected(ti: any) {
    this.leaseItem.Name = ti.Text;
  }

  get netAmount() {
    const price = this.leaseItem.UnitPrice;
    return (price - (price * this.leaseItem.DiscountPercent / 100.0)).toFixed(2);
  }

  get referenceUser(): any {
    return { 
      Id: this.leaseItem.ReferenceUserId, 
      Text: this.leaseItem.ReferenceUserText 
    };
  }

  set referenceUser(r: any) {
    this.leaseItem.ReferenceUserId   = r?.Id;
    this.leaseItem.ReferenceUserText = r?.Text ?? '';
  }

  async onSubmit() {
    this.isBusy = true;
    await this.leaseItem.Save();
    this.isBusy = false;

    this.$router.push(`/Lease/${+this.$route.params.leaseId}/Items`);
  }

  async fetchData() {
    this.isBusy = true;

    this.leaseInfo = await LeaseEdit.Find(+this.$route.params.leaseId);

    if (this.isEdit) {
      this.leaseItem = await LeaseItemEditTextItem.Find(+this.$route.params.leaseItemId);
    } else {
      this.leaseItem.LeaseId = +this.$route.params.leaseId;

      this.leaseItem.ReferenceUserId   = this.leaseInfo!.NewItemReferenceUserId;
      this.leaseItem.ReferenceUserText = this.leaseInfo!.NewItemReferenceUserText;

      const availableVats = await VatList.FetchAll('pt', false);
      if (availableVats.Items.length) {
        this.leaseItem.VatId = availableVats.Items[0].Id;
      }
    }

    this.isBusy = false;
  }

  async mounted() {
    await this.$nextTick();
    await this.fetchData();
  }
}
