




















































































































import {
  Prop,
  Watch,
  Component,
  Vue
} from 'vue-property-decorator';
import LeaseItemEditRentalObject, {
  ILeaseItemEditRentalObject
} from '@/modules/LeaseItem/EditRentalObject';
import RentalTypeList, {
  IRentalTypeList_Item
} from '@/modules/RentalType/List';
import User from '@/modules/User';
import LeaseEdit from '@/modules/Lease/Edit';

@Component
export default class EditRentalObject extends Vue {
  @Prop() readonly isEdit ? : boolean;

  isBusy = true;
  inventoryItemSelectInvalidFeedback ? = null as string | null;
  leaseInfo: LeaseEdit | null = new LeaseEdit();
  leaseItem = new LeaseItemEditRentalObject();
  userCanAccessTransports = false;
  driverUserSuggestions: any[] = [];

  get showDateRange(): boolean {
    return !this.rentalType.ForceIdenticalRentalDates;
  }

  get rentalTypeLabel(): string {
    return (this.rentalType || {}).Text || this.$t('Amount');
  }

  get rentalType(): any {
    let rentalType;

    if (this.leaseItem.RentalTypes)
      rentalType = this.leaseItem.RentalTypes.find(rt => rt.Value === this.leaseItem.RentalType);

    return rentalType;
  }

  get deliveryDriverUser(): any {
    return { 
      Id: this.leaseItem.DeliveryDriverUserId, 
      Text: this.leaseItem.DeliveryDriverUserText 
    };
  }

  set deliveryDriverUser(d: any) {
    this.leaseItem.DeliveryDriverUserId   = d?.Id;
    this.leaseItem.DeliveryDriverUserText = d?.Text ?? '';
  }

  get pickupDriverUser(): any {
    return { 
      Id: this.leaseItem.PickupDriverUserId, 
      Text: this.leaseItem.PickupDriverUserText 
    };
  }

  set pickupDriverUser(d: any) {
    this.leaseItem.PickupDriverUserId   = d?.Id;
    this.leaseItem.PickupDriverUserText = d?.Text ?? '';
  }

  get referenceUser(): any {
    return { 
      Id: this.leaseItem.ReferenceUserId, 
      Text: this.leaseItem.ReferenceUserText 
    };
  }

  set referenceUser(r: any) {
    this.leaseItem.ReferenceUserId   = r?.Id;
    this.leaseItem.ReferenceUserText = r?.Text ?? '';
  }

  get inventoryItem(): any {
    if (!this.leaseItem.InventoryItemId) return null;

    return {
      Id: this.leaseItem.InventoryItemId,
      ObjectNumber: this.leaseItem.InventoryItemNumber,
      Name: this.leaseItem.Name
    };
  }

  get rentedTo(): any {
    return this.leaseItem.RentedTo;
  }

  set rentedTo(rt: any) {
    this.leaseItem.RentedTo = rt;
  }

  set inventoryItem(ii: any) {
    this.inventoryItemSelectInvalidFeedback = null;

    const newInventoryItem = ii || {} as any;

    this.leaseItem.InventoryItemId = newInventoryItem.Id;
    this.leaseItem.InventoryItemNumber = newInventoryItem.ObjectNumber;
    this.leaseItem.InventoryType = newInventoryItem.InventoryType;
    this.leaseItem.RentalObjectId = newInventoryItem.ObjectId;
    this.leaseItem.VatId = newInventoryItem.DefaultVatId;
    this.leaseItem.Unit = newInventoryItem.Unit;

    if (this.leaseItem.RentalTypes) {
      const rentalType = this.leaseItem.RentalTypes.find((rt: any) => rt.Text === newInventoryItem.RentalType);
      this.leaseItem.RentalType = rentalType.Value || this.leaseItem.RentalTypes[0].Value;
    }

    this.leaseItem.Name = newInventoryItem.Name || this.leaseItem.Name;

    this.updateRentalInfo(true);
    this.updateDiscountPercent();
  }

  get netAmount() {
    const price = this.leaseItem.Quantity * (this.leaseItem.RentedAmount ?? 1) * this.leaseItem.UnitPrice;
    return (price - (price * this.leaseItem.DiscountPercent / 100.0)).toFixed(2);
  }

  async updateRentedAmount() {
    const {
      RentedFrom: fromDate,
      RentedFromTime: fromTime,
      RentedTo: toDate,
      RentedToTime: toTime,
      RentalType: rentalType,
      RentalObjectId: rentalObjectId,
      LeaseId: leaseId,
      RentedStart: startDate,
    } = this.leaseItem;

    const {
      CustomerId: customerId,
      CustomerAccount: customerProject,
      CurrencyId: currencyId,
    } = this.leaseInfo!;

    if (!rentalObjectId)
      return;

    if (toDate)
      this.leaseItem.RentedAmount = await this.$store.state.hyrma.client.Calendar.GetRentedAmount(fromDate, fromTime, toDate, toTime, rentalType, rentalObjectId, leaseId, customerId, customerProject);
    else
    {
      this.leaseItem.RentedAmount = null;
      this.leaseItem.RentedToTime = undefined; 
    }
  }

  async fetchDriverUserSuggestions(
    search: string,
    loading: Function | null = null
  ) {
    if (loading) loading(true);

    this.driverUserSuggestions = await User.Drivers_Autocomplete(search);

    if (loading) loading(false);
  }

  async updateUnitPrice(forceUpdate = false) {
    const {
      RentedFrom: fromDate,
      RentedFromTime: fromTime,
      RentedTo: toDate,
      RentedToTime: toTime,
      RentalType: rentalType,
      RentalObjectId: rentalObjectId,
      LeaseId: leaseId,
      RentedStart: startDate,
      RentedAmount: rentedAmount,
    } = this.leaseItem;

    const {
      CustomerId: customerId,
      CustomerAccount: customerProject,
      CurrencyId: currencyId,
    } = this.leaseInfo!;

    if (!rentalObjectId)
      return;

    const { price, isStaggered } = await this.$store.state.hyrma.client.Customer.GetRentalUnitPrice(currencyId, rentalType, rentalObjectId, customerId, customerProject, startDate, fromDate, leaseId, rentedAmount);

    if (forceUpdate || this.leaseItem.UnitPrice == 0 || (price != 0 && isStaggered))
      this.leaseItem.UnitPrice = price;

  }

  async updateDiscountPercent() {
    const {
      RentedFrom: fromDate,
      RentedFromTime: fromTime,
      RentedTo: toDate,
      RentedToTime: toTime,
      RentalType: rentalType,
      RentalObjectId: rentalObjectId,
      LeaseId: leaseId,
      RentedStart: startDate,
    } = this.leaseItem;

    const {
      CustomerId: customerId,
      CustomerAccount: customerProject,
      CurrencyId: currencyId,
    } = this.leaseInfo!;

    if (!rentalObjectId)
      return;

    if (this.leaseItem.IsDiscounted)
      this.leaseItem.DiscountPercent = await this.$store.state.hyrma.client.Customer.GetDiscountPercent(customerId, customerProject, rentalObjectId, rentalType);
    else
      this.leaseItem.DiscountPercent = 0;
  }

  async updateRentalInfo(forceUpdatePrice = false) {
    await this.updateRentedAmount();
    this.updateUnitPrice(forceUpdatePrice);
  }

  async onSubmit() {
    if (this.leaseItem.InventoryItemId === null) {
      this.inventoryItemSelectInvalidFeedback = this.$t('Please select inventory item');

      return;
    }

    if (this.leaseItem.RentedAmount && !this.leaseItem.RentedTo) {
      return;
    }

    this.isBusy = true;
    await this.leaseItem.Save();
    this.isBusy = false;

    this.$router.push(`/Lease/${+this.$route.params.leaseId}/Items`);
  }

  async fetchData() {
    this.isBusy = true;

    this.leaseInfo = await LeaseEdit.Find(+this.$route.params.leaseId);

    if (this.isEdit) {
      this.leaseItem = await LeaseItemEditRentalObject.Find(+this.$route.params.leaseItemId);
    } else {
      this.leaseItem.LeaseId = +this.$route.params.leaseId;
      const rentalTypes = await RentalTypeList.FetchAll();
      this.leaseItem.RentalTypes = rentalTypes.Items.map((rt: IRentalTypeList_Item) => ({
        Text: rt.Name,
        Value: rt.Id,
        ForceIdenticalRentalDates: rt.ForceIdenticalRentalDates
      }));

      if (this.leaseItem.RentalTypes.length) {
        this.leaseItem.RentalType = this.leaseItem.RentalTypes[0].Value;
      }

      this.leaseItem.TransportStatuses = this.leaseItem.TransportStatuses.map((ts: any) => ({
        Text: this.$t(ts.Text),
        Value: ts.Value
      }));

      this.leaseItem.ReferenceUserId   = this.leaseInfo!.NewItemReferenceUserId;
      this.leaseItem.ReferenceUserText = this.leaseInfo!.NewItemReferenceUserText;
    }

    this.userCanAccessTransports = await User.Can('AccessTransports');

    await this.fetchDriverUserSuggestions('');

    this.isBusy = false;
  }

  async mounted() {
    await this.$nextTick();
    await this.fetchData();
  }
}
